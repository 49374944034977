.language-card {
    width: 100%;
    padding: 13px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}
